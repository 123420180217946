import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import {
  Box,
  Divider,
  Typography,
  Stack,
  MenuItem,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  TextField,
  Grid,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Autocomplete from '@mui/material/Autocomplete';
// Utils
import axios from '../../../utils/axios';
// routes
import { PATH_DASHBOARD, PATH_AUTH } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import MyAvatar from '../../../components/MyAvatar';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';

// ----------------------------------------------------------------------
const PORT = window.location.hostname === 'localhost' ? '8080' : '8443';

const MENU_OPTIONS = [
  {
    label: 'Inicio',
    linkTo: '/',
  },
  {
    label: 'Configurações',
    linkTo: PATH_DASHBOARD.user.account,
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const navigate = useNavigate();

  const { user, logout, update } = useAuth();

  const isMountedRef = useIsMountedRef();

  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [modalOpen, setModalOpen] = useState(null);
  const [isSubmittings, setIsSubmitting] = useState(false);
  const [selectCompany, setSelectCompany] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate(PATH_AUTH.login, { replace: true });

      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  const handleCompany = async () => {
    const resCompany = await axios.get(`/api/company-manager`);
    if (resCompany.status === 200) {
      setCompanies(resCompany.data);
    }

    setModalOpen(true);
  };

  const handleSelectCompany = async (company) => {
    setSelectCompany(company);
  };

  const changeCompany = async () => {
    try {
      const nUser = { ...user };
      setIsSubmitting(true);
      if (selectCompany._id === undefined || selectCompany._id === null || selectCompany._id === '') {
        enqueueSnackbar('Selecione uma empresa!', { variant: 'error' });
        setIsSubmitting(false);
        return;
      }
      const res = await axios.put(`/api/user/${user._id}`, {
        companyId: selectCompany._id,
        switchCompany: true,
      });
      if (res.status === 200) {
        enqueueSnackbar('Empresa alterada com sucesso!', { variant: 'success' });
        nUser.company = selectCompany;
        nUser.companyId = selectCompany._id;
        update(nUser);
        setIsSubmitting(false);
        setModalOpen(null);
        handleClose();
        // navigate(PATH_DASHBOARD.root);
        window.location = PATH_DASHBOARD.root;
      } else {
        enqueueSnackbar('Erro ao alterar empresa!', { variant: 'error' });
        setIsSubmitting(false);
        handleClose();
      }
    } catch (error) {
      enqueueSnackbar('Erro ao alterar empresa!', { variant: 'error' });
      setModalOpen(null);
      setIsSubmitting(false);
      handleClose();
    }
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <MyAvatar />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} to={option.linkTo} component={RouterLink} onClick={handleClose}>
              {option.label}
            </MenuItem>
          ))}

          {(user?.acl.profile === 'master' ||
            user?.acl.profile === 'administrator' ||
            user?.acl.profile === 'superadmin') && <MenuItem onClick={handleCompany}>Mudar empresa</MenuItem>}
        </Stack>
        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Sair
        </MenuItem>
      </MenuPopover>
      {modalOpen && (
        <Dialog open={modalOpen} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Escolha a empresa</DialogTitle>
          <DialogContent style={{ marginTop: 10 }}>
            <DialogContentText>Escolha na lista abaixo a empresa que deseja monitorar</DialogContentText>
            <Autocomplete
              options={companies}
              value={selectCompany}
              renderInput={(params) => <TextField {...params} label="Empresas" />}
              getOptionLabel={(option) => `${option.name}`}
              renderOption={(props, option) => (
                <li {...props} style={{ width: '100%', zIndex: 100000 }}>
                  <Grid container alignItems="center">
                    <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                      <Box component="span" sx={{ fontWeight: 'regular' }}>
                        <object
                          alt={option.name}
                          data={`${window.location.protocol}//${window.location.hostname}:${PORT}/external-api/company-logo/${option?._id}`}
                          type="image/png"
                          height={40}
                        >
                          <img src="/images/image-preview.png" alt={option.name} height={40} />
                        </object>
                      </Box>
                      <Typography fontSize={20} fontWeight={600} color="text.primary">
                        {option.name}
                      </Typography>
                    </Grid>
                  </Grid>
                </li>
              )}
              onChange={(e, v) => handleSelectCompany(v)}
              sx={{ mt: 2 }}
            />
          </DialogContent>
          <DialogActions style={{ justifyContent: 'center' }}>
            <Button onClick={() => setModalOpen(null)} color="inherit" variant="contained">
              Cancelar
            </Button>
            <LoadingButton
              variant="contained"
              style={{ color: 'white' }}
              loading={isSubmittings}
              onClick={() => {
                changeCompany();
              }}
            >
              Gerenciar
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
